<template>
    
    <div class="details--container" key="arrangements-details">
        <div class="action--bar">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <router-link to="/arrangementen" tag="a" class="btn btn-secondary btn-icon">
                            <img src=@/assets/img/icons/arrow-left.svg>Terug naar overzicht
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="image--block pos-right">
            <div class="image--block-content col-lg-6 col-md-4">
                <background-image image="koeien-2.jpg" prefix="arrangementen/big/"></background-image>
            </div>
            <div class="container">
                <div class="row section">
                    <div class="col-lg-5 col-md-7">
                        <h3>Grote rondvaart in de Biesbosch</h3>
                        <p>
                            Deze tocht duurt 2,5 uur en voert u verder de Biesbosch in. U krijgt een mooi beeld van het prachtige natuurgebied de Biesbosch met de mooie rietkragen, de dotterbloem, de vele soorten vogels, bevers, hekrunderen en als u goed oplet ziet u misschien nog een ijsvogeltje vliegen! U vaart met een platbodem met open kuip, zodat u de natuur goed kunt zien.
                        </p>
                        <p>
                            Tijdens deze tocht zorgen we voor een hapje en een drankje afhankelijk van het jaargetijde. De tocht kan zowel in de zomer als de winter gevaren worden afhankelijk van de weersomstandigheden.
                        </p>
                        <p>
                            De kosten zijn € 99,50 met maximaal 4 personen.
                        </p>
                        <p>
                            Verdere mogelijkheden zijn bespreekbaar zoals een bevertocht, vaar -en wandeltocht, snerttocht.
                        </p>
                        <router-link to="/reserveren" tag="a" class="btn btn-secondary btn-icon">
                            <img src="@/assets/img/icons/calendar-check.svg" />Nu boeken
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    metaInfo: {
        title: 'Grote rondvaart door de Biesbosch',
        meta: [
            { vmid: 'description', name: 'description', content: 'Een grote rondvaart met gids. Deze tocht duurt 2,5 uur en voert u verder de Biesbosch in door smalle kreken. U vaart dan met een kajuitboot met open kuip, zodat u de natuur goed kunt zien.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/arrangementen/grote-rondvaart'}
        ]
    }
}
</script>
